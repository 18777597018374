import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import vueInstance from '@/main'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import auth from "@/middleware/auth";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/pages/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Home',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Home',
            active: true,
            to: { name: 'home' },
          },
        ],
      },
    },
    {
      path: '/contacts',
      props: true, // pass url parameters as props to component
      name: 'contacts',
      component: () => import('@/views/pages/contacts/Contacts.vue'),
      meta: {
        pageTitle: 'Contacts',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
            to: { name: 'contacts' },
          },
        ],
      },
    },
    {
      path: '/contacts/:contactId',
      props: true, // pass url parameters as props to component
      name: 'edit-contact',
      component: () => import('@/views/pages/contacts/EditContact.vue'),
      meta: {
        pageTitle: 'Edit Contact',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Contacts',
            active: true,
            to: { name: 'contacts' },
          },
          {
            text: 'Edit contact',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/pages/account/AccountSettingGeneral.vue'),
      meta: {
        pageTitle: 'Account Settings',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
            to: { name: 'account' },
          },
        ],
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/views/pages/account/ChangePassword.vue'),
      meta: {
        pageTitle: 'Account Settings',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Change Password',
            active: true,
            to: { name: 'change-password' },
          },
        ],
      },
    },
    {
      path: '/billing/set-payment-method',
      name: 'set-payment-method',
      component: () => import('@/views/pages/billing/SetPaymentMethod.vue'),
      meta: {
        pageTitle: 'Set Payment Method',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Set Payment Method',
            active: true,
            to: { name: 'set-payment-method' },
          },
        ],
      },
    },
    {
      path: '/billing/select-plan',
      name: 'select-plan',
      component: () => import('@/views/pages/billing/SelectPlan.vue'),
      meta: {
        pageTitle: 'Select Plan',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Select Plan',
            active: true,
            to: { name: 'select-plan' },
          },
        ],
      },
    },
    {
      path: '/installation',
      name: 'installation',
      component: () => import('@/views/pages/account/Installation.vue'),
      meta: {
        pageTitle: 'Installation',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
        ],
      },
    },
    {
      path: '/installation/wordpress',
      props: { serviceName: 'wordpress' },
      name: 'install-instruction-wordpress',
      component: () => import('@/views/pages/account/Instruction.vue'),
      meta: {
        pageTitle: 'Installation in Wordpress',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
          {
            text: 'Wordpress',
            active: true,
          },
        ],
      },
    },
    {
      path: '/installation/wix',
      props: { serviceName: 'wix' },
      name: 'install-instruction-wix',
      component: () => import('@/views/pages/account/Instruction.vue'),
      meta: {
        pageTitle: 'Installation in Wix',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
          {
            text: 'Wix',
            active: true,
          },
        ],
      },
    },
    {
      path: '/installation/squarespace',
      props: { serviceName: 'squarespace' },
      name: 'install-instruction-squarespace',
      component: () => import('@/views/pages/account/Instruction.vue'),
      meta: {
        pageTitle: 'Installation in Squarespace',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
          {
            text: 'Squarespace',
            active: true,
          },
        ],
      },
    },
    {
      path: '/installation/weebly',
      props: { serviceName: 'weebly' },
      name: 'install-instruction-weebly',
      component: () => import('@/views/pages/account/Instruction.vue'),
      meta: {
        pageTitle: 'Installation in Weebly',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
          {
            text: 'Weebly',
            active: true,
          },
        ],
      },
    },
    {
      path: '/installation/churchco',
      props: { serviceName: 'churchco' },
      name: 'install-instruction-churchco',
      component: () => import('@/views/pages/account/Instruction.vue'),
      meta: {
        pageTitle: 'Installation in Church.co',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Installation',
            active: true,
            to: { name: 'installation' },
          },
          {
            text: 'Church.co',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses',
      name: 'locations',
      component: () => import('@/views/pages/locations/ListLocation.vue'),
      meta: {
        pageTitle: 'Campuses',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
        ],
      },
    },
    {
      path: '/campuses/new',
      name: 'new-location',
      component: () => import('@/views/pages/locations/NewLocation.vue'),
      meta: {
        pageTitle: 'Edit Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId/services/new',
      props: true, // pass url parameters as props to component
      name: 'new-recurring-event',
      component: () => import('@/views/pages/locations/NewRecurringEvent.vue'),
      meta: {
        pageTitle: 'View Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId/services/:recurringEventId',
      props: true, // pass url parameters as props to component
      name: 'edit-recurring-event',
      component: () => import('@/views/pages/locations/EditRecurringEvent.vue'),
      meta: {
        pageTitle: 'View Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId/services/:recurringEventId/date/:recurringEventDate/exception',
      props: true, // pass url parameters as props to component
      name: 'add-recurring-event-exception',
      component: () => import('@/views/pages/locations/WizardForScheduledEventException.vue'),
      meta: {
        pageTitle: 'Recurring event exception',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Add recurring event exception',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId/single-services/new',
      props: true, // pass url parameters as props to component
      name: 'new-single-event',
      component: () => import('@/views/pages/locations/NewSingleEvent.vue'),
      meta: {
        pageTitle: 'View Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId/single-services/:singleEventId',
      props: true, // pass url parameters as props to component
      name: 'edit-single-event',
      component: () => import('@/views/pages/locations/EditSingleEvent.vue'),
      meta: {
        pageTitle: 'View Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campuses/:locationId',
      props: true, // pass url parameters as props to component
      name: 'edit-location',
      component: () => import('@/views/pages/locations/EditLocation.vue'),
      meta: {
        pageTitle: 'Edit Campus',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Campuses',
            active: true,
            to: { name: 'locations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/communication/templates/:tabId?',
      props: true, // pass url parameters as props to component
      name: 'list-communication-templates',
      component: () => import('@/views/pages/communication/ListCommunicationTemplates.vue'),
      meta: {
        pageTitle: 'Communication templates',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Communication templates',
            active: true,
            to: { name: 'list-communication-templates' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/communication/templates/edit/:templateId',
      props: true, // pass url parameters as props to component
      name: 'edit-communication-template',
      component: () => import('@/views/pages/communication/EditCommunicationTemplate.vue'),
      meta: {
        pageTitle: 'Edit Template',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Communication templates',
            active: true,
            to: { name: 'list-communication-templates' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/widgets/cta',
      name: 'edit-cta-widget',
      component: () => import('@/views/pages/widgets/EditCtaWidget.vue'),
      meta: {
        pageTitle: 'Visitor Form',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Visitor Form',
            active: true,
            to: { name: 'edit-cta-widget' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/widgets/scheduled',
      name: 'edit-scheduled-widget',
      component: () => import('@/views/pages/widgets/EditScheduledWidget.vue'),
      meta: {
        pageTitle: 'Thank You Message',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Thank You Message',
            active: true,
            to: { name: 'edit-scheduled-widget' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations',
      name: 'integrations',
      component: () => import('@/views/pages/integrations/ListIntegration.vue'),
      meta: {
        pageTitle: 'Integrations',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
            to: { name: 'integrations' },
          },
        ],
      },
    },
    {
      path: '/integrations/:integrationName',
      props: true, // pass url parameters as props to component
      name: 'edit-integration',
      component: () => import('@/views/pages/integrations/EditIntegration.vue'),
      meta: {
        pageTitle: 'Edit Integration',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
            to: { name: 'integrations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations/:integrationName/setup',
      props: true, // pass url parameters as props to component
      name: 'setup-integration',
      component: () => import('@/views/pages/integrations/SetupIntegration.vue'),
      meta: {
        pageTitle: 'Edit Integration',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
            to: { name: 'integrations' },
          },
          {
            text: 'Setup',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations/mailchimp',
      props: true, // pass url parameters as props to component
      name: 'mailchimp',
      component: () => import('@/views/pages/integrations/mailchimp/Edit.vue'),
      meta: {
        pageTitle: 'Edit Integration',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
            to: { name: 'integrations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/integrations/planning-center',
      props: true, // pass url parameters as props to component
      name: 'planning-center',
      component: () => import('@/views/pages/integrations/planning-center/Edit.vue'),
      meta: {
        pageTitle: 'Edit Integration',
        requiresAuth: true,
        breadcrumb: [
          {
            text: 'Integrations',
            active: true,
            to: { name: 'integrations' },
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        requiresAuth: true,
        // middleware: [auth],
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
            to: { name: 'second-page' },
          },
        ],
      },
    },
    {
      path: '/auth/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/pages/authentication/Signup.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/auth/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/auth/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function performForcedRedirects(to, next) {
  if (process.env.VUE_APP_DONT_REQUIRE_PAYMENT_SETUP === '1') {
    // useful for development without Stripe account connected
    return next()
  }
  const authUser = store.getters['auth/authUser']
  if (!authUser.is_payment_method_added && to.name !== 'set-payment-method') {
    vueInstance.$toast({
      component: ToastificationContent,
      props: {
        title: 'Setup is not finished',
        icon: 'BellIcon',
        text: 'Enter a payment method to access the rest of the app!',
        variant: 'warning',
      },
    }, {
      timeout: 3000,
    })

    // payment method not added
    return next({
      name: 'set-payment-method',
      query: { redirect: to.fullPath },
    })
  } if (authUser.is_payment_method_added && authUser.is_subscription_selection_required
    && to.name !== 'select-plan' && to.name !== 'set-payment-method') {
    // payment method added but plan not selected
    vueInstance.$toast({
      component: ToastificationContent,
      props: {
        title: 'Setup is not finished',
        icon: 'BellIcon',
        text: 'Select your plan to access the rest of the app!',
        variant: 'warning',
      },
    }, {
      timeout: 3000,
    })

    return next({
      name: 'select-plan',
      query: { redirect: to.fullPath },
    })
  }
  return next()
}

router.beforeEach(async (to, from, next) => {
  // const disabledUrls = {
  //   integrations: process.env.VUE_APP_INTEGRATIONS_ENABLED !== '1',
  //   'edit-integrations': process.env.VUE_APP_INTEGRATIONS_ENABLED !== '1',
  // }
  //
  // // // eslint-disable-next-line no-bitwise
  // if (!!(disabledUrls[to.name] | false) !== false) {
  //   return next({ name: 'error-404' })
  // }

  const authUser = store.getters['auth/authUser']
  const reqAuth = to.matched.some(record => record.meta.requiresAuth)

  if (reqAuth) {
    if (!authUser) {
      // we won't render the component until user is loaded. This guarantees all components with reqAuth flag
      // will have store.getters['auth/authUser'] available when loaded
      await store.dispatch('auth/getAuthUser').then(() => {
        if (!store.getters['auth/authUser']) {
          // not logged in, redirect to login
          return next({
            path: '/auth/login',
            query: { redirect: to.fullPath },
          })
        }
        return performForcedRedirects(to, next)
      })
    } else {
      return performForcedRedirects(to, next)
    }
  }
  return next()
})

router.onError(error => {
  if ((/loading chunk .* failed./i.test(error.message) || /SyntaxError: Unexpected token /i.test(error.message))
    && window.location.hash !== '#retry') {
    window.location.reload()
    // the chunk might no longer be available due to a recent redeployment of the page
    // mark the page to don't trigger reload infinitely
    window.location.hash = '#retry'
    window.location.reload()
  }
})

export default router
