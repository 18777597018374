/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import vueInstance from '@/main'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as Sentry from '@sentry/vue'

export const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: true, // required to handle the CSRF token
})

/*
 * Add a response interceptor
 */
apiClient.interceptors.response.use(
  response => response,
  error => {
    const baseToastTimeout = 4000
    if (error.response && error.response.status) {
      // redirect to login page in case of 401. The only exception is 401 returned
      // by the API when PC integration refresh token is expired. In this case don't do anything and let
      // the client code handle it.
      if ((error.response.status === 401 && !['RefreshTokenExpired', 'RequiresSubscriptionException'].includes(error.response.data.error))
        || error.response.status === 419) {
        store.dispatch('auth/logout')
      } else if (error.response.status === 500) {
        vueInstance.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'BellIcon',
            text: `An error occurred: ${error.response.data.message || 'Server error'}`,
            variant: 'danger',
          },
        }, {
          timeout: baseToastTimeout,
        })
        Sentry.captureMessage(error.response.data)
      } else if (error.response.data.error === 'RequiresSubscriptionException') {
        // TODO: move this up to cleanup if logic
        router.push({ name: 'select-plan' })
      }
    } else {
      vueInstance.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          text: 'We\'ve encountered a server connection error. Please try again, the admins has been notified.',
          variant: 'danger',
        },
      }, {
        timeout: baseToastTimeout,
      })
      Sentry.captureMessage(error)
    }
    return Promise.reject(error)
  },
)
