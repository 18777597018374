import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { app } from '@/main'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// Global Filters
import './global-filters'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Composition API
Vue.use(VueCompositionAPI)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // tracingOrigins: ['app.visitplanner.church', /^\//],
    }),
  ],
  trackComponents: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

export default new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('userConfig/initialiseStore')
  },
  render: h => h(App),
}).$mount('#app')
